import React from "react";
import { useStaticQuery, graphql, Link } from 'gatsby';

import "../styles/styles.css";

import Layout from "../Components/Layout";

const _ = require('lodash');

export default function Articles() {
    const data = useStaticQuery(graphql`
    query MyQuery {
        allMarkdownRemark {
          nodes {
            id
            fileAbsolutePath
            frontmatter {
              title
              date(locale: "")
              slug
              excerpt
            }
            timeToRead
          }
        }
      }      
    `);
    
    /* 
      TODO: The article filtering and sorting logic needs to be part of graphql.
    */
    const articles = data.allMarkdownRemark.nodes.filter(node => node.fileAbsolutePath.includes("/blog-articles/"));

    const chronologicallySortedArticles = _.reverse(_.sortBy(articles, [function(article) { return article.frontmatter.date; }]));
    
    return <Layout>
        <h1 className="mt-6 font-extrabold md:text-3xl text-2xl">Articles - Index</h1>
        <main>
            {
            chronologicallySortedArticles.map(article => {
                return <article key={article.id}>
                    <h3 className="blog-article-link">
                        <Link to={article.frontmatter.slug}>
                          {article.frontmatter.title}
                        </Link>
                    </h3>
                    <small className="blog-article-meta font-bold">
                      {new Date(article.frontmatter.date).toDateString()} 
                      &nbsp;&nbsp; - &nbsp;
                      <span className="blog-article-meta font-normal"> {article.timeToRead} mins read</span>
                    </small>
                    <p className="mt-1 text-base">{article.frontmatter.excerpt}</p>
                </article>;
            })
            }
        </main>
    </Layout>;
}